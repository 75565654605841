import React from "react";

import CountryPage from "../../components/country-page";

const ANDROID_BTN_CONFIG = {
  os: "android",
  href:
    "https://play.google.com/store/apps/details?id=com.letiagoalves.radiotuga",
  target: "_blank",
  text: "Download para Android",
};
const IOS_BTN_CONFIG = {
  os: "ios",
  href: "https://apps.apple.com/pt/app/radio-tuga/id1453204194",
  target: "_self",
  text: "Download para iOS",
};

export default () => (
  <CountryPage
    countryCode="pt"
    androidBtnConfig={ANDROID_BTN_CONFIG}
    iosBtnConfig={IOS_BTN_CONFIG}
    headline1={
      "Faz download gratuitamente da aplicação Rádio Tuga e ouve mais de 100 emissoras portuguesas!"
    }
    headline2={
      "A Rádio Tuga foi desenvolvida para fazer chegar a rádio Portuguesa a todos os cantos do mundo"
    }
    appName="Rádio Tuga"
  />
);
